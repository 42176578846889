{
  ".data..percpu": [128640, 4852],
  ".data.once": [133616, 477],
  ".data..percpu..shared_aligned": [158464, 3216],
  ".init.setup": [592344, 900],
  "__param": [620764, 980],
  ".initcallrootfs.init": [621792, 4],
  ".initcall6.init": [621796, 64],
  ".initcallearly.init": [621860, 28],
  ".initcall5.init": [621888, 28],
  ".initcall4.init": [621916, 36],
  ".initcall1.init": [625408, 24],
  ".initcall7.init": [625432, 16],
  ".initcall2.init": [625644, 20],
  ".initcall3s.init": [630876, 4],
  ".initcall7s.init": [630880, 4]
}
